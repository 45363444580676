import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { isImmutable } from "immutable";
import {
	createInvestor,
	listInvestors,
} from "../../../../actions/investors.actions";
import { formatIdPretty } from "../../../helpers/users";
import history from "../../../../interfaces/history";

import {
	EVENT_TYPE_SHARES_INVESTMENT_CREATE,
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
} from "/shared/constants";
import { Button } from "@/components/ui/button";
import { ItemList, ItemListRow, ItemListColumn } from "@/components/itemList";
import i18n, { formatNumber } from "@/i18n";
import { Trans } from "react-i18next";
import { Sparkles } from "lucide-react";
import withResolvedProps from "@/hocs/withResolvedProps";

const { t } = i18n;

class InvestorsList extends Component {
	investorRefs = {};

	componentDidMount = () => {
		const { listInvestors } = this.props;
		listInvestors(false);
	};

	componentDidUpdate = () => {
		this.scrollToSelectedInvestor();
		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, listInvestors } = this.props;
		const investorCreate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_CREATE);
		const investorUpdate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_UPDATE);
		const investorDelete = audit.get(EVENT_TYPE_SHARES_INVESTMENT_DELETE);

		if (
			(investorCreate && investorCreate.get("refresh") === true) ||
			(investorUpdate && investorUpdate.get("refresh") === true) ||
			(investorDelete && investorDelete.get("refresh") === true)
		) {
			listInvestors();
		}
	};

	scrollToSelectedInvestor = () => {
		const { selectedInvestorId } = this.props;

		if (!selectedInvestorId) {
			return;
		}

		const htmlElement = this.investorRefs[selectedInvestorId];

		if (htmlElement && htmlElement.scrollIntoViewIfNeeded) {
			htmlElement.scrollIntoViewIfNeeded();
		}
	};

	setInvestorRef = (ref) => {
		if (!ref) {
			return;
		}

		this.investorRefs[ref.getAttribute("data-refid")] = ref;
	};

	/**
	 * The render function
	 */
	render() {
		const {
			investors,
			isLoading,
			createInvestor,
			basePath,
			selectedInvestorId,
			sorting,
			setSorting,
			hasAppliedFilters,
		} = this.props;

		if (!isImmutable(investors)) {
			return null;
		}

		const isZeroState =
			!isLoading && !hasAppliedFilters && investors.size === 0;

		if (isZeroState) {
			return (
				<div className="flex flex-col h-full items-center gap-12 max-w-[1000px] m-auto text-center bg-background rounded-xl p-20">
					<div className="text-xl">
						{t("shares.shareholders.breadcrumbs.title")}
					</div>
					<Trans i18nKey="shareholders.zero_state.description" />
					<div className="flex gap-6">
						<Button
							onClick={() => {
								const { pathname } = window.location;
								const urlAlias = pathname.split("/")[1];
								history.push(`/${urlAlias}/shares/setup`);
							}}
						>
							<Sparkles />
							{t("service.shares.setup")}
						</Button>
						<Button
							variant="secondary"
							onClick={() => {
								createInvestor({}, (err, investor) => {
									if (err) return;
									history.push(`${basePath}/${investor.get("id")}`);
								});
							}}
						>
							{t("add_new_shareholder")}
						</Button>
					</div>
				</div>
			);
		}

		return (
			<ItemList
				onSort={setSorting}
				sorting={sorting}
				rightAlignLastColumn
				columnSizing="1fr 90px 100px"
				columns={[
					{
						id: "name",
						label: t("name"),
						isSortable: true,
					},
					{
						id: "ownership",
						label: t("ownership"),
						isSortable: true,
						className: "justify-center",
					},
					{
						id: "shares",
						label: t("shares"),
						isSortable: true,
						className: "justify-end",
					},
				]}
				zeroStateRenderer={() =>
					t("shares.shareholders.details.toolbar.no_visible_shareholders")
				}
			>
				{investors
					.toJS()
					.sort((a, b) => {
						if (!sorting) return undefined;

						const [sortBy, sortDesc] = sorting;

						const x = sortDesc ? b : a;
						const y = sortDesc ? a : b;

						switch (sortBy) {
							case "name":
								return x.investorInformation?.name?.localeCompare?.(
									y.investorInformation?.name,
								);
							case "ownership":
								return (
									(x?.details?.ownershipPercentage ?? 0) -
									(y?.details?.ownershipPercentage ?? 0)
								);
							case "shares":
								return (
									(x?.details?.numOfTotalShares ?? 0) -
									(y?.details?.numOfTotalShares ?? 0)
								);

							default:
								return undefined;
						}
					})
					.map((investor) => {
						const name =
							investor.investorTypeOfOwner === "capitalInsurance"
								? `${t("investments.capital_insurance.insurance_owner")}: ${
										investor.captialIncuranceOwnerInformation?.name ?? "n/a"
								  }`
								: investor.investorInformation?.name;

						return (
							<ItemListRow
								isSelected={
									selectedInvestorId === investor.id &&
									!history.location.pathname.endsWith(
										"register-of-shareholders",
									)
								}
								key={investor.id}
								onClick={() => {
									history.push(`${basePath}/${investor.id}`);
								}}
							>
								<ItemListColumn.User
									className="flex-1"
									id={investor.investorId ?? investor.id}
									name={name}
									extra={formatIdPretty(investor.investorInformation?.id)}
								/>
								<ItemListColumn className="justify-center">
									{
										+parseFloat(
											investor.details?.ownershipPercentage ?? 0,
										).toFixed(1)
									}
									%
								</ItemListColumn>
								<ItemListColumn>
									{formatNumber(+(investor.details?.numOfTotalShares ?? 0))}
								</ItemListColumn>
							</ItemListRow>
						);
					})}
			</ItemList>
		);
	}
}

function mapStateToProps(state) {
	return {
		investors: state.investors.get("visibleInvestors"),
		isLoading: state.investors.get("isFetchingInvestors"),
		selectedInvestorId: state.investors.getIn(["selectedInvestor", "id"]),
		filterBy: state.investors.get("filterBy"),
		hasAppliedFilters: state.investors.get("hasAppliedFilters"),
		i18n: state.i18n,
		audit: state.audit.get("investors"),
	};
}

const mapActionsToProps = {
	listInvestors,
	createInvestor,
};

/** Export */
const InvestorsListConnected = connect(
	mapStateToProps,
	mapActionsToProps,
)(InvestorsList);

export default withResolvedProps(() => {
	const [sorting, setSorting] = useState(["name", false]);
	return { sorting, setSorting };
})(InvestorsListConnected);
