/* 
	// Usage:
	const MyInput = ({ validation }) => 
		<div>
			<input className={cn(className, validation.className)} />
			{validation.elements}
		</div>;

	export default withValidation()(MyInput);

	// Then Apply validation to a component

	<MyInput validationMessage="This field is required" />
	<MyInput validationMessage="This value is perfect" validationVariant="success" />
	<MyInput validationMessage=["Must be at least 2 chars.", "Must include numerals"] />
*/

import React, { forwardRef } from "react";
import { cn } from "@/components/utils";
import { arrayOf, oneOf, oneOfType, string } from "prop-types";

const CLASSNAMES_INPUT = {
	destructive: "border border-destructive text-destructive",
	success: "border border-success text-success",
	warning: "border border-warning text-warning",
};

const CLASSNAMES_ELEMENTS = {
	destructive: "text-destructive",
	success: "text-success",
	warning: "text-amber-500 font-semibold",
};

const withValidation = () => {
	return (Component) => {
		const ComponentWithValidation = forwardRef((props, ref) => {
			const { validationVariant = "destructive", validationMessage } = props;

			let validation = {
				active: false,
				className: "",
				elements: null,
			};

			if (props.validationMessage) {
				validation.active = true;
				validation.className = CLASSNAMES_INPUT[validationVariant];

				const messages = (
					Array.isArray(validationMessage)
						? validationMessage
						: [validationMessage]
				).filter(Boolean);

				validation.elements = messages.length && (
					<div>
						{messages.map((error) => (
							<div
								key={error}
								className={CLASSNAMES_ELEMENTS[validationVariant]}
							>
								{messages.length > 1 ? "- " : ""}
								{error}
							</div>
						))}
					</div>
				);
			}

			return <Component {...props} validation={validation} ref={ref} />;
		});

		ComponentWithValidation.displayName = `withValidation(${Component.displayName})`;

		ComponentWithValidation.propTypes = {
			validationMessage: oneOfType([string, arrayOf(string)]),
			validationVariant: oneOf(["success", "warning", "destructive"]),
		};

		return ComponentWithValidation;
	};
};

export const ValidationArea = withValidation()(({ children, validation }) => (
	<div>
		<div
			className={cn(
				"transition-all",
				{ "p-4 rounded": validation.active },
				validation.className,
			)}
		>
			{children}
		</div>
		{validation.elements}
	</div>
));

export default withValidation;
