import React, { lazy, Suspense, useState } from "react";
import DialogModal from "@/components/dialogModal";
import LoadingPanel from "../loadingPanel";
import PropTypes from "prop-types";
import { CreditCard, LockOpen } from "lucide-react";

import { Button } from "../ui/button";
import SubscriptionPlanSelector from "@/components/subscriptions/subscriptionPlanSelector";
import SubscriptionCheckoutModal from "../subscriptions/subscriptionCheckoutModal";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import { useTranslation } from "react-i18next";

const promotions = {
	"share-issues-basic": lazy(() => import("./promotions/share-issues-basic")),
	"share-register-basic": lazy(() =>
		import("./promotions/share-register-basic"),
	),
	"e-signing-basic": lazy(() => import("./promotions/e-signing-basic")),
	"shareholders-export-excel": lazy(() =>
		import("./promotions/import-export-excel"),
	),
	"share-register-export-excel": lazy(() =>
		import("./promotions/import-export-excel"),
	),
	"share-register-export-pdf": lazy(() =>
		import("./promotions/share-register-export-pdf"),
	),
	"shareholders-export-owner-report": lazy(() =>
		import("./promotions/shareholders-export-owner-report"),
	),
};

const UpgradePlanDialog = ({ feature, requiredPlan, onCancel, ...props }) => {
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [selectedAccountPlanId, setSelectedAccountPlanId] = useState();
	const { t } = useTranslation();

	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;

	const Promotion = promotions[feature];

	const isCurrentPlanSelected =
		subscription?.accountPlanId === selectedAccountPlanId;

	return (
		<>
			<DialogModal
				{...props}
				borderless
				className="dialog-sheen"
				icon={<LockOpen className="text-orange-400" />}
				footerRenderer={() => null}
				size="lg"
				title={t("subscriptions.upgrade_your_plan")}
				onCancel={onCancel}
				bodyRenderer={() => (
					<div className="flex h-full w-full gap-4">
						<div className="flex flex-col flex-1">
							<Suspense fallback={<LoadingPanel />}>{<Promotion />}</Suspense>
						</div>
						<div className="flex-1 flex flex-col gap-6">
							<SubscriptionPlanSelector
								availableAccountPlans={["lite", "standard", "premium"]}
								requiredAccountPlan={requiredPlan}
								onChange={setSelectedAccountPlanId}
							/>
							<Button
								disabled={isCurrentPlanSelected}
								size="lg"
								onClick={() => setShowCheckoutModal(true)}
							>
								<CreditCard /> {t("subscriptions.checkout.go_to_payment")}
							</Button>
						</div>
					</div>
				)}
			/>
			{showCheckoutModal && (
				<SubscriptionCheckoutModal
					onCancel={() => setShowCheckoutModal(false)}
					selectedAccountPlanId={selectedAccountPlanId}
					onPurchaseComplete={() => onCancel?.()}
				/>
			)}
		</>
	);
};

UpgradePlanDialog.propTypes = {
	feature: PropTypes.string.isRequired,
	requiredPlan: PropTypes.oneOf(["lite", "standard", "premium"]),
	onCancel: PropTypes.func.isRequired,
};

export default UpgradePlanDialog;
