import React, { useEffect, useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { arrayOf, func, oneOf } from "prop-types";
import useSubscriptionPlans from "@/hooks/subscriptions/useSubscriptionPlans";
import { Skeleton } from "@/components/ui/skeleton";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import SubscriptionAccountPlanCard from "@/components/subscriptions/subscriptionAccountPlanCard";
import { useTranslation } from "react-i18next";
import useDialogModal from "@/hooks/useDialogModal";
const VALID_ACCOUNT_PLANS = ["lite", "standard", "premium"];

const SubscriptionPlanSelector = ({
	requiredAccountPlan,
	onChange,
	availableAccountPlans,
}) => {
	const { t, i18n } = useTranslation();
	const [isBillingMonthly, setBillingMontly] = useState(false);

	const { dialogElement, alert } = useDialogModal();

	const { data: subscriptionPlansQuery } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansQuery?.data;

	const { data: subscriptionQuery, isLoading: isSubscriptionLoading } =
		useSubscription();
	const subscription = subscriptionQuery?.data;

	const currentAccountPlanId = subscription?.accountPlanId;
	const currentAccountPlanName = subscription?.accountPlan?.metadata.plan;
	const isCurrentPlanBillingMonthly =
		subscription?.accountPlan?.interval === "month";

	const [selectedAccountPlan, setSelectedAccountPlan] =
		useState(requiredAccountPlan);

	// Set the selected account plan to the current account plan if it's not set
	// (as soon as the current subscription plan has loaded)
	useEffect(() => {
		if (!selectedAccountPlan && currentAccountPlanName)
			setSelectedAccountPlan(currentAccountPlanName);
	}, [selectedAccountPlan, currentAccountPlanName]);

	useEffect(() => {
		setBillingMontly(isCurrentPlanBillingMonthly);
	}, [isCurrentPlanBillingMonthly]);

	useEffect(() => {
		if (!subscriptionPlans) return;

		onChange?.(
			subscriptionPlans.account[isBillingMonthly ? "month" : "year"].find(
				(p) => p.metadata.plan === selectedAccountPlan,
			)?.id,
		);
	}, [selectedAccountPlan, subscriptionPlans, isBillingMonthly]);

	const isLoading = !subscriptionPlans || isSubscriptionLoading;

	return (
		<Tabs onValueChange={setBillingMontly} value={isBillingMonthly}>
			<TabsList className="grid w-full grid-cols-2 mb-8">
				<TabsTrigger value={true}>
					{t("subscriptions.plans.billed_monthly")}
				</TabsTrigger>
				<TabsTrigger value={false}>
					{t("subscriptions.plans.billed_yearly")}{" "}
					<Badge variant="outline-orange">
						{t("subscriptions.plans.save_discount", { discount: "10%" })}
					</Badge>
				</TabsTrigger>
			</TabsList>
			<div className="flex flex-col gap-4" value={0}>
				{isLoading
					? availableAccountPlans.map((p) => (
							<Skeleton key={p} className="h-[87px] mb-2" />
					  ))
					: subscriptionPlans.account[isBillingMonthly ? "month" : "year"]
							.filter((p) => availableAccountPlans.includes(p.metadata.plan))
							.map((p) => (
								<SubscriptionAccountPlanCard
									plan={p}
									key={p.id}
									isRequired={requiredAccountPlan === p.metadata.plan}
									isCurrent={
										currentAccountPlanId === p.id ||
										subscriptionPlans.account[
											!isBillingMonthly ? "month" : "year"
										].find(
											(siblingPlan) =>
												siblingPlan.metadata.plan === p.metadata.plan,
										)?.id === currentAccountPlanId
									}
									isCurrentPlanBillingMonthly={isCurrentPlanBillingMonthly}
									isHighlighted={selectedAccountPlan === p.metadata.plan}
									onClick={() => setSelectedAccountPlan(p.metadata.plan)}
								/>
							))}
				<Button
					variant="link"
					onClick={() => {
						alert(
							t("subscriptions.pricing_plans"),
							<div className="relative h-full">
								<iframe
									className="absolute w-full h-full border-none"
									src={`https://invono.se/${
										i18n.language.startsWith("sv") ? "sv/" : ""
									}pricing-embed`}
									height="100%"
								></iframe>
							</div>,
							{
								className: "h-full",
								size: "xl",
							},
						);
					}}
				>
					{t("subscriptions.view_full_price_table")}
				</Button>
			</div>
			{dialogElement}
		</Tabs>
	);
};

SubscriptionPlanSelector.propTypes = {
	requiredAccountPlan: oneOf(VALID_ACCOUNT_PLANS),
	availableAccountPlans: arrayOf(oneOf(VALID_ACCOUNT_PLANS)),
	onChange: func.isRequired,
};

SubscriptionPlanSelector.defaultProps = {
	availableAccountPlans: ["free", "lite", "standard", "premium"],
};

export default SubscriptionPlanSelector;
