import React from "react";
import { Trans, useTranslation } from "react-i18next";
import moment from "../../modules/moment.module";

import Panel from "@/dumb-components/shared/panel/panel";
import { Button } from "@/components/ui/button";
import Alert from "@/components/alert";

import useCancelCompanyAccount from "@/hooks/companies/useCancelCompanyAccount";
import useReactivateCompanyAccount from "@/hooks/companies/useReactivateCompanyAccount";
import useCompanyFromUrl from "@/hooks/companies/useCompanyFromUrl";
import useDialogModal from "@/hooks/useDialogModal";

const CancelAccountContainer = () => {
	const { t } = useTranslation();
	const { data: companyQuery } = useCompanyFromUrl();
	const company = companyQuery?.data;
	const companyId = company?.id;

	const { confirm, dialogElement } = useDialogModal();

	const {
		mutateAsync: cancelCompanyAccount,
		isPending: isCancelingCompanyAccount,
	} = useCancelCompanyAccount(companyId);

	const {
		mutateAsync: reactivateCompanyAccount,
		isPending: isReactivatingCompanyAccount,
	} = useReactivateCompanyAccount(companyId);

	const onClickCancelAccount = async () => {
		const isCancelConfirmed = await confirm(
			t("subscriptions.cancel_account"),
			<Trans i18nKey="subscriptions.cancel_account_delete_all" />,
			{ variant: "destructive", submitText: t("subscriptions.cancel_account") },
		);

		if (!isCancelConfirmed) return;

		await cancelCompanyAccount();
	};

	const onClickReactivateCompany = async () => {
		await reactivateCompanyAccount();
	};

	const companyWillBeCanceled = !!company?.toBeCanceledAt;
	const remainingDays = moment(company?.toBeCanceledAt).diff(moment(), "days");

	return (
		<>
			{companyWillBeCanceled ? (
				<Panel mode="light" title="subscription.account_will_be_canceled">
					<div className="flex flex-col items-baseline gap-4 p-6">
						<Alert
							variant="destructive"
							message={
								<Trans
									i18nKey="subscriptions.cancel_account.days_remaining_of_paid_subscription"
									values={{ remainingDays }}
								/>
							}
						/>

						<Button
							variant="secondary"
							isLoading={isReactivatingCompanyAccount}
							onClick={onClickReactivateCompany}
						>
							{t("subscriptions.button.undo_cancellation")}
						</Button>
					</div>
				</Panel>
			) : (
				<Panel mode="light" title="subscriptions.cancel_account" marginBottom>
					<div className="flex flex-col items-baseline gap-4 p-6">
						<p>{t("subscriptions.when_you_cancel_company_account")}</p>
						<Button
							isLoading={isCancelingCompanyAccount}
							onClick={onClickCancelAccount}
							variant="destructive"
						>
							{t("subscriptions.button.cancel_account")}
						</Button>
					</div>
				</Panel>
			)}
			{dialogElement}
		</>
	);
};

export default CancelAccountContainer;
