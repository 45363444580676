import * as React from "react";

import { string, bool, node } from "prop-types";

import { cn } from "@/components/utils";
import { LoaderCircle } from "lucide-react";
import withValidation from "../../hocs/withValidation";

let Input = React.forwardRef(
	(
		{ className, type, isLoading, validation, hint, label, id, ...props },
		ref,
	) => {
		return (
			<div className="relative">
				{label && (
					<label htmlFor={id} className="mb-1">
						{label}
					</label>
				)}
				{hint && <Hint>{hint}</Hint>}
				<input
					id={id}
					type={type}
					className={cn(
						"flex h-10 w-full rounded-sm border border-input bg-background px-4 py-7 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-accent disabled:border-black/40",
						validation.className,
						className,
					)}
					ref={ref}
					{...props}
				/>
				{isLoading && (
					<div className="w-full h-full top-0 absolute flex items-center justify-end pe-2 pointer-events-none text-muted-foreground">
						<LoaderCircle size={16} className="animate-spin" />
					</div>
				)}
				{validation.elements}
			</div>
		);
	},
);

Input.propTypes = {
	className: string,
	type: string,
	isLoading: bool,
	hint: node,
};

Input.displayName = "Input";

const Hint = ({ children }) => (
	<p className="text-sm text-muted-foreground mb-2">{children}</p>
);

Input = withValidation()(Input);

export { Input, Hint };
