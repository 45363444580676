import React, { createContext, createElement, useContext } from "react";
import { array, arrayOf, bool, func, node, shape, string } from "prop-types";
import withTooltip from "@/hocs/withTooltip";

import {
	Calendar,
	EllipsisVertical,
	SearchX,
	SortAsc,
	SortDesc,
} from "lucide-react";

import Moment from "@/modules/moment.module";
import UserAvatar from "@/components/userAvatar";
import { cn } from "@/components/utils";
import DropdownMenu from "@/components/dropdownMenu";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import Tooltip from "@/components/tooltip";
import { default as BadgesRenderer } from "@/components/badges";

const ItemListContext = createContext(null);
const ItemListRowContext = createContext(null);

export const ItemList = ({
	children,
	className,
	columns,
	columnSizing,
	rightAlignLastColumn,
	sorting,
	onSort,
	zeroStateRenderer,
}) => {
	const [sortBy, sortDesc] = sorting ?? [undefined, false];

	const zeroStateContent = children?.length > 0 ? null : zeroStateRenderer?.();

	return (
		<ItemListContext.Provider
			value={{ columnSizing, columns, rightAlignLastColumn }}
		>
			<div className={cn("flex flex-col shadow rounded-lg h-full", className)}>
				{columns && (
					<div
						style={{ gridTemplateColumns: columnSizing }}
						className="grid gap-4 items-center justify-between h-[56px] backdrop-blur-xl px-[16px] bg-[rgba(255,255,255,0.4)] border border-edge border-b-0 rounded-t-lg min-h-[56px]"
					>
						{columns.map((column, idx) => (
							<ItemListColumn
								className={cn(
									"flex font-bold",
									{
										"justify-end":
											rightAlignLastColumn && idx === columns.length - 1,
									},
									column.className,
								)}
								title={column.tooltip}
								key={column.id}
							>
								{column.isSortable ? (
									<Button
										variant="ghost"
										className={"group px-0"}
										onClick={() =>
											onSort([
												column.id,
												sortBy !== column.id ? false : !sortDesc,
											])
										}
									>
										<div
											className={cn("flex items-center gap-1", {
												"flex-row-reverse": idx === columns.length - 1,
											})}
										>
											{column.label}
											{createElement(sortDesc ? SortDesc : SortAsc, {
												size: 16,
												className: cn("opacity-0 transition-opacity", {
													"group-hover:opacity-30": sortBy !== column.id,
													"opacity-80": sortBy === column.id,
												}),
											})}
										</div>
									</Button>
								) : (
									column.label
								)}
							</ItemListColumn>
						))}
					</div>
				)}
				{(children?.length || zeroStateContent) && (
					<div
						className={cn(
							"flex flex-col border border-edge rounded-b-lg overflow-auto h-full",
							{ "rounded-t-lg": !columns },
							className,
						)}
					>
						{zeroStateContent && (
							<div className="flex justify-center items-center gap-2 bg-background h-[56px]">
								<SearchX className="!text-slate-500" size={16} />{" "}
								{zeroStateContent}
							</div>
						)}
						{children}
					</div>
				)}
			</div>
		</ItemListContext.Provider>
	);
};

ItemList.propTypes = {
	children: node,
	className: string,
	sorting: array,
	onSort: func,
	isSortable: bool,
	columns: arrayOf(
		shape({
			id: string,
			label: node,
			className: string,
		}),
	),
	zeroStateRenderer: func,
};

export const ItemListRow = ({
	children,
	isSelected,
	isChecked,
	onClick,
	className,
}) => {
	const itemListContext = useContext(ItemListContext);
	return (
		<ItemListRowContext.Provider value={{ isSelected, isChecked }}>
			<div
				role="row"
				aria-selected={isSelected}
				onClick={onClick}
				style={{ gridTemplateColumns: itemListContext.columnSizing }}
				className={cn(
					"grid gap-4 items-center justify-between bg-background border border-y-slate-200 border-transparent py-[8px] px-[16px] border-b-0 first:border-t-0 last:rounded-b-lg transition-colors",
					{
						"first:!rounded-t-lg": !itemListContext.columns,
						"cursor-pointer hover:bg-gradient-to-r hover:from-primary/10 hover:to-background hover:shadow-[white_inset_0_0_50px]":
							typeof onClick === "function",
						"bg-gradient-to-r from-primary/20 to-background shadow-[white_inset_0_0_50px]":
							isSelected || isChecked,
						"border-2 border-primary border-y-primary !border-y-2 py-[7px] px-[14px] [&+div]:border-t-0":
							isSelected,
					},
					className,
				)}
			>
				{children}
			</div>
		</ItemListRowContext.Provider>
	);
};

export const ItemListColumnBase = ({ children, className }) => {
	const itemListContext = useContext(ItemListContext);

	return (
		<div
			className={cn(
				"flex w-full justify-start",
				{
					"last:justify-end": itemListContext.rightAlignLastColumn,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

ItemListColumnBase.displayName = "ItemListColumnBase";

export const ItemListColumn = withTooltip()(ItemListColumnBase);

const Date = ({ children, format = "L", className }) => {
	return (
		<Tooltip value={Moment(children).format("llll")}>
			<div
				className={cn("inline-flex gap-2 overflow-hidden truncate", className)}
			>
				<Calendar size={16} className="text-gray-400" />
				{Moment(children).format(format)}
			</div>
		</Tooltip>
	);
};

const User = ({ id, name, extra, className }) => {
	return (
		<div className={cn("flex gap-4 overflow-hidden", className)}>
			<UserAvatar userId={id} userName={name} />
			<div className="flex justify-center flex-col overflow-hidden">
				<div className="font-semibold truncate" title={name}>
					{name}
				</div>
				{extra?.length && (
					<div className="text-foreground/60 truncate" title={extra}>
						{extra}
					</div>
				)}
			</div>
		</div>
	);
};

const Actions = ({ options }) => (
	<DropdownMenu
		trigger={
			<Button variant="ghost">
				<EllipsisVertical />
			</Button>
		}
		options={options}
	/>
);

const Badges = ({ data, maxCount = 1 }) => (
	<div>
		<BadgesRenderer data={data} maxCount={maxCount} />
	</div>
);

const Check = () => {
	const context = useContext(ItemListRowContext);

	return <Checkbox checked={context.isChecked} />;
};

ItemListColumn.Date = Date;
ItemListColumn.User = User;
ItemListColumn.Actions = Actions;
ItemListColumn.Checkbox = Check;
ItemListColumn.Badges = Badges;
