import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS, List, Map } from "immutable";
import { object, bool, func, oneOf } from "prop-types";
import { listInvestors } from "../../actions/investors.actions";

import Select from "@/components/select";

class InvestmentsSelectContainer extends Component {
	state = {
		activeInvestments: List(),
		allInvestments: List(),
	};

	static propTypes = {
		visibilityMode: oneOf(["ALL_INVESTMENTS", "ACTIVE_INVESTMENTS"]),
		includeOwnCompany: bool,
		onChange: func,
		tmpTransaction: object,
	};

	static defaultProps = {
		visibilityMode: "ALL_INVESTMENTS",
		includeOwnCompany: false,
	};

	componentDidMount = () => {
		const { investments, listInvestments } = this.props;

		if (!investments || investments.size === 0) {
			listInvestments();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { investments } = this.props;
		if (investments !== prevProps.investments) {
			this.parseInvestments();
		}
	};

	parseInvestments = () => {
		const { investments, companyId, includeOwnCompany } = this.props;

		if (!investments) {
			return;
		}

		const activeInvestments = [];
		const allInvestments = [];

		investments.forEach((inv) => {
			const numOfTotalShares = inv.getIn(["details", "numOfTotalShares"]);
			const investorId = inv.get("investorId");

			if (numOfTotalShares && numOfTotalShares !== 0) {
				activeInvestments.push(inv);
			}

			// TO - Exclude my own company
			if (includeOwnCompany && investorId !== companyId) {
				return;
			}

			allInvestments.push(inv);
		});

		this.setState({
			activeInvestments: fromJS(activeInvestments),
			allInvestments: fromJS(allInvestments),
		});
	};

	getOptions = () => {
		const { visibilityMode, investmentIdFrom, investmentIdTo } = this.props;
		const { allInvestments, activeInvestments } = this.state;
		let options = List();

		if (visibilityMode === "ALL_INVESTMENTS") {
			options = allInvestments.map((inv) => {
				return Map({
					value: inv.get("id"),
					label: inv.getIn(["investorInformation", "name"]),
				});
			});
			if (investmentIdFrom) {
				options = options.filter((item) => {
					return item.get("value") !== investmentIdFrom;
				});
			}
		} else if (visibilityMode === "ACTIVE_INVESTMENTS") {
			options = activeInvestments.map((inv) => {
				return Map({
					value: inv.get("id"),
					label: inv.getIn(["investorInformation", "name"]),
				});
			});
			if (investmentIdTo) {
				options = options.filter((item) => {
					return item.get("value") !== investmentIdTo;
				});
			}
		}

		return options;
	};

	formatOptionLabel = (option) => {
		const { allInvestments } = this.state;

		const investment = allInvestments.find((i) => i.get("id") === option.value);

		const name = investment.getIn(["investorInformation", "name"]);
		let id = investment.getIn(["investorInformation", "id"]);

		if (investment.get("investorTypeOfOwner") === "capitalInsurance") {
			id = investment.getIn(["investorInformation", "insuranceNr"]);
			return (
				<div className="Select-value">
					<span className="Select-value-label">
						{name} <span className="text--muted">{id}</span>
					</span>
				</div>
			);
		} else {
			return (
				<div className="Select-value">
					<span className="Select-value-label">
						{name} <span className="text--muted">{id}</span>
					</span>
				</div>
			);
		}
	};

	render = () => {
		const { selectedInvestmentId, onChange } = this.props;

		const options = this.getOptions().toJS();

		return (
			<Select
				value={selectedInvestmentId}
				options={options}
				onChange={onChange}
				optionRenderer={this.formatOptionLabel}
			/>
		);
	};
}

function mapStoreToProps(store) {
	return {
		investments: store.investors.get("list"),
		companyId: store.company.company && store.company.company.id,
	};
}

const mapActionsToProps = {
	listInvestments: listInvestors,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(InvestmentsSelectContainer);
