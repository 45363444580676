import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default (companyId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["subscriptionRefillPrepaid", companyId],
		mutationFn: () => {
			return apiClient.post(`subscriptions/refill-prepaid`, undefined, {
				params: {
					companyId,
				},
			});
		},
		onSettled: (mutationResponse) => {
			queryClient.setQueryData(["subscription", companyId], mutationResponse);
			queryClient.invalidateQueries(["subscriptionHelper", companyId]);
		},
	});
};
