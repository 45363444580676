import React from "react";
import { arrayOf, func, shape, string } from "prop-types";
import { cn } from "@/components/utils";
import withValidation from "@/hocs/withValidation";

import {
	Select as BaseSelect,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

let Select = ({
	value,
	className,
	onChange,
	options,
	optionRenderer,
	isLoading,
	placeholder,
	validation,
}) => {
	return (
		<div>
			<BaseSelect disabled={isLoading} value={value} onValueChange={onChange}>
				<SelectTrigger
					isLoading={isLoading}
					className={cn(className, validation.className)}
				>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					{options.map((option) => (
						<SelectItem
							key={`${option.label}__${option.value}`}
							value={option.value}
						>
							{optionRenderer(option)}
						</SelectItem>
					))}
				</SelectContent>
			</BaseSelect>
			{validation.elements}
		</div>
	);
};

Select.propTypes = {
	value: string,
	onChange: func,
	options: arrayOf(shape({ value: string, label: string })),
	optionRenderer: func,
};

Select.defaultProps = {
	options: [],
	optionRenderer: ({ label }) => label,
};

Select = withValidation()(Select);

export default Select;
